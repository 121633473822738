body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
.App {
  text-align: center;
}

.App {
  background-color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
h4 {
  color: #094063;
}
